<template>
    <div class="fixed customer-search">
        <van-search
            v-model="searchWeChatName"
            placeholder="请输入微信名搜索"
            show-action
            clearable
            @search="onSearch"
            @clear="onClear"
            :left-icon="'https://wechatapppro-1252524126.cos.ap-shanghai.myqcloud.com/appAKLWLitn7978/image/krbu79qj0sos.png'"
        >
            <template #action>
                <div @click="onSearch" class="search">搜索</div>
            </template>
        </van-search>
        <div class="search-result">
            <div
                class="list"
                v-for="list in searchResultList"
                :key="list.customer_id"
                @click="goCustomerDetail(list.customer_id, list.state, list.merchant_key_id)"
            >
                <div class="avatar">
                    <img v-default-avatar :src="list.wx_avatar" />
                </div>
                <div class="name">
                    <p v-html="highLight(list.wx_nickname)">{{ list.wx_nickname }}</p>
                </div>
            </div>
            <empty-page v-if="isEmpty" :msg="emptyMsg" :isStaffPromotion="true" />
        </div>
    </div>
</template>
<script>
import { fetchCustomerList, getStaffId } from '@/views/staffPromotion/api';
import emptyPage from '@/components/emptyPage';
import { getCookie } from '@/utils/cookies.js';

export default {
    components: {
        emptyPage
    },
    data() {
        return {
            searchWeChatName: '',
            searchResultList: [],
            isEmpty: false,
            jumpParams: {}
        };
    },
    computed: {
        emptyMsg() {
            return `搜索无结果`;
        }
    },
    watch: {
        searchWeChatName(newVal) {
            if (newVal.match(/^[ ]*$/)) {
                this.searchResultList = [];
                this.isEmpty = false;
            }
        }
    },
    created() {
        this.askStaffID();
    },
    methods: {
        async askStaffID() {
            let appId = getCookie('with_app_id');
            const clientType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            let client = clientType === 'Mobile' ? 0 : 1;
            let res = await getStaffId({
                user_id: localStorage.getItem('b_user_id'),
                from: client,
                app_id: appId
            });
            this.jumpParams = {
                key: res.data.key,
                account: res.data.wework_staff_id,
                host: res.data.host,
                app_id: appId
            };
        },
        async goMerchantList(value) {
            if (this.isGray) return;
            this.jumpParams.business_opportunity_id = value;
            console.log('this.params', this.jumpParams);
            const { key, account, business_opportunity_id, host, app_id } = this.jumpParams;
            const machineType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            if (machineType === 'Mobile') {
                console.log('移动端', navigator.userAgent);
                window.location.href = `${host}api/crm/market_login_business_detail?key=${key}&account=${account}&business_opportunity_id=${business_opportunity_id}`;
            } else {
                console.log('Pc端', navigator.userAgent);
                window.location.href = `${host}crm/market_login_business_detail?key=${key}&account=${account}&business_opportunity_id=${business_opportunity_id}&app_id=${app_id}`;
            }
        },
        goCustomerDetail(customer_id, state = 0, business_opportunity_id = '') {
            if (state == 2 || state == 3) {
                this.goMerchantList(business_opportunity_id);
                return;
            }
            if (!customer_id || state === 5) return;
            this.$router.push({
                name: 'customerDetail',
                query: {
                    customer_id,
                    state
                }
            });
        },
        onClear() {
            this.isEmpty = false;
            this.searchResultList = [];
        },
        onSearch() {
            if (this.searchWeChatName.match(/^[ ]*$/)) return;
            fetchCustomerList({ customer_name: this.searchWeChatName, page: 1, page_size: 20 }).then((res) => {
                this.searchResultList = res?.data?.list;
                if (res && res.data && res.data.list) {
                    res.data.list.length ? (this.isEmpty = false) : (this.isEmpty = true);
                }
            });
        },
        // 搜索 关键词高亮
        highLight(name) {
            let regex = new RegExp(this.searchWeChatName, 'gi');
            let lightWord = name.match(regex);
            if (lightWord) {
                name = name.replace(lightWord[0], `<font style="color: #1472ff">${lightWord[0]}</font>`);
            }
            return name;
        }
    }
};
</script>
<style lang="scss" scoped>
.customer-search {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    .search {
        margin-left: 0.16rem;
        margin-right: -0.16rem;
        font-size: 0.28rem;
    }
    .van-search {
        padding: 0.2rem 0.32rem;
        .van-search__content {
            height: 0.8rem;
        }
    }
    .search-result {
        border-radius: 0.2rem;
        margin-left: 0.32rem;
        .list {
            display: flex;
            align-items: center;
            height: 0.96rem;
            .avatar {
                width: 10%;
                img {
                    width: 0.72rem;
                    height: 0.72rem;
                    border-radius: 50%;
                }
            }
            .name {
                width: 80%;
                font-size: 0.28rem;
                border-bottom: 0.02rem solid #eee;
                line-height: 0.96rem;
                margin-left: 0.16rem;
                p {
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                }
            }
        }
    }
    .list:last-of-type .name {
        border: none;
    }
}
</style>
